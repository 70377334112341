<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Alert :message="requestError" type="alert-danger" v-if="requestError" />
    <!-- Modal -->
    <div
      class="modal fade"
      id="deleteModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Delete Course</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Are you sure you want to do this ?
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              @click="deleteCourse"
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal end -->
    <div class="row">
      <div class="col-md-12 content_margin_bottom">
        <div class="row">
          <div class="col-md-12 mb-4">
            <h4 class="card-title my-3 float-right">
              <router-link
                :to="`/entity/${entityId}/course/add`"
                class="btn btn-success btn-sm mr-3"
                >New Course</router-link
              >
            </h4>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Card -->

            <div class="table-responsive mb-0 courses__row">
              <div
                class="courses__col"
                v-for="course in courses"
                :key="course.id"
              >
                <b-card
                  class="m-0"
                  :title="course.title"
                  :img-src="url + course.avatar"
                  @click="getDetails(course.id)"
                  :img-alt="course.title"
                  img-top
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sorrt-desc.sync="sortDesc"
                  :fit-by.sync="sortBy"
                  :solter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                >
                  <b-card-text>
                    {{ course.overview | truncate(40, "...") }}
                  </b-card-text>
                </b-card>
                <div class="d-flex justify-content-end bg-light p-2">
                  <button
                    @click="getKeyAndId(course.id)"
                    class="btn btn-danger"
                    data-toggle="modal"
                    data-target="#deleteModal"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../layout/index.vue";
import PageHeader from "@/components/page-header";
import Alert from "@/components/Alert.vue";
import appConfig from "@/app.config";
import store from "@/state/store";
// import toasterMixin from "@/mixins/toasterMixin.js";
// import url from "@/helpers/url";

export default {
  page: {
    title: "Courses",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Alert,
    PageHeader,
    Layout,
  },
  data() {
    return {
      // data table meta
      entityId: this.$route.params.id,
      url: `https://staging.afcfta.app`,

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      sortDesc: false,
      sortBy: "name",
      filter: null,
      filterOn: [],
      pageOptions: [10, 25, 50, 100],
      //end of table meta

      loading: false,
      id: null,
      requestError: null,
      success: null,
      error: null,
      courses: [],
      courseID: null,
      title: "Courses",
      items: [
        {
          text: "Entity Admin",
        },
        {
          text: "Courses",
          active: true,
        },
      ],
    };
  },
  methods: {
    getDetails(courseId) {
      this.$router.push(`/entity/${this.entityId}/course/${courseId}`);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getKeyAndId(id) {
      this.courseID = id;
    },
    deleteCourse() {
      const data = {
        courseID: this.courseID,
      };
      store
        .dispatch("course/deleteCourse", data)
        .then((response) => {
          if (response) {
            this.fetchData();
            this.toast({
              message: "Course Deleted.",
              type: "success",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.data == undefined) {
            this.toast({
              message:
                "Network error. Check your network connection and try again.",
              type: "error",
            });
          }
        });
    },
    fetchData() {
      store
        .dispatch("course/fetchCourses", this.entityId)
        .then((response) => {
          this.courses = response.data.course;
          console.log("courses", this.courses);
        })
        .catch((err) => {
          if (err?.response?.data === undefined)
            this.requestError = "An error occured";
        });
    },
  },
  computed: {
    rows() {
      return this.courses && this.courses.length;
    },
  },
  created() {
    this.fetchData();
    this.totalRows = this.courses.length;
  },
  mounted() {
    console.log(this.totalRows);
  },

  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
};
</script>
<style>
#card img,
img {
  width: 100%;
  height: 340px;
  display: block;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

.courses__row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 1rem;
  padding: 10px;
}

.courses__col {
  height: 400px;
  height: auto;
  width: 100%;
  box-shadow: 2px 2px 2px 1px rgba(138, 137, 137, 0.2);
}

.courses__colText {
  padding: 10px;
}

.courses__colTitle {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
